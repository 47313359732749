<template>
  <div class="store-details">
    <a-spin :spinning="loading">
        <div v-if="userInfo">
            <!-- 导航 -->
            <a-breadcrumb separator=">" class="breadcrumb">
                <a-breadcrumb-item>
                    <a @click.prevent="$router.push('/index')">首页</a>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <a>{{userInfo.supplierName}}</a>
                </a-breadcrumb-item>
            </a-breadcrumb>
            <a-card title="店铺信息">
                <div class="store-title" :class="(userInfo.icStatus === 'APPLYING' || userInfo.icStatus === 'CANCELLED') && 'store-details-apply'">{{userInfo.supplierName }}<span v-if="userInfo.icStatus === 'APPLYING' || userInfo.icStatus === 'CANCELLED'"><span>（{{userInfo.icStatus | unit}}）</span></span></div>
                <!-- <div class="store-rate" v-if="!isApply">
                    好评率
                    <a-rate :value="3" />
                </div> -->
                <a-row v-if="userInfo.icStatus === 'REGISTERED'">
                    <a-col :span="8">
                        <b>创建账号：</b>{{userInfo.createAccount}}
                    </a-col>
                    <a-col :span="8" v-if="userInfo.unifiedCode">
                        <b>营业执照号：</b>{{userInfo.unifiedCode}}
                    </a-col>
                    <a-col :span="8" v-if="userInfo.licenseRegTime">
                        <b>成立时间：</b>{{userInfo.licenseRegTime}}
                    </a-col>
                    <a-col :span="8">
                        <b>联系电话：</b>{{userInfo.legalPhone}}
                    </a-col>
                    <a-col :span="8">
                        <b>完成订单：</b><b>{{userInfo.orderFinishCount}} 笔</b>
                    </a-col>
                </a-row>
            </a-card>

            <a-card title="个人信息" v-if="userInfo.icStatus !== 'CANCELLED'">
                <a-row>
                    <a-col :span="6">
                        <b>姓名：</b>{{userInfo.legalName}}
                    </a-col>
                    <a-col :span="6">
                        <b>联系电话：</b>{{userInfo.legalPhone}}
                    </a-col>
                    <a-col :span="6">
                        <b>身份证号：</b>{{userInfo.idCard}}
                    </a-col>
                    <a-col :span="6">
                        <b>申请时间：</b>{{userInfo.createdTime}}
                    </a-col>
                </a-row>
            </a-card>

            <div class="card-permit" v-if="userInfo.busiLicenseUrl">
                <p>营业执照</p>
                
                <div class="img">
                    <img :src="userInfo.busiLicenseUrl" alt="营业执照照片">
                    <div class="mark" @click="show(userInfo.busiLicenseUrl)"><a-icon type="eye"/></div>
                </div>
            </div>
        </div>

        <blank v-else />
    </a-spin>
    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="营业执照照片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import {queryStoreInfo} from '@/api/store'
import {name} from '@/utils/constArr'
import blank from './components/blank.vue'
export default {
    data() {
        return {
            isApply: false, // 是否是申请中
            userInfo: {},
            loading: true,
            imgVisible: false,
            previewImage: ''
        }
    },
    components: {
        blank
    },
    filters: {
        // transition(val) {
        //     if (!val) return
        //     const suffix = val.substring(val.length - 2)
        //     const prefix = val.substring(0,3)
        //     console.log(val,prefix + '******' + suffix)
        //     return prefix + '******' + suffix
        // },
      unit: (val) => name[val]
    },
    mounted() {
        queryStoreInfo(this.$route.params.id).then(res => {
            this.loading = false
            if (res.code === 200) {
                this.userInfo = res.data
            } else {
                this.userInfo = ''
            }
        }).catch(err => {
            console.log(err)
            this.loading = false
            this.userInfo = ''
        })
    },
    methods: {
        show (url) {
            this.previewImage = url
            this.imgVisible = true
        }
    }
}
</script>

<style lang="less" scoped>
.store-details {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
        padding: 0 15px;
    }
    .store-title {
        display: inline-block;
        width: 900px;
        font-size: 20px;
        margin-bottom: 24px;
    }
    .store-rate {
        display: inline-block;
        font-weight: 700;
        ul {
            margin-left: 16px;
        }
    }
    .card-permit {
        padding: 24px;
        border: 1px solid #e8e8e8;
        background: #fff;
        p {
            font-size: 16px;
            margin-bottom: 24px;
        }
        img {
            width: 360px;
        }
        .img {
        width: 300px;
        position: relative;
        .mark {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            transition: all .3s;
            opacity: 0;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .4);
            font-size: 18px;
        }
        &:hover {
            .mark {
                opacity: 1;
            }
        }
        img {
            width: 100%;
        }
        }
    }
}
.store-details-apply {
    color: #999;
    margin-bottom: 3px !important;
}
/deep/ .ant-card {
    margin-bottom: 28px;
}
/deep/ .ant-card-head {
    font-weight: 700;
    font-size: 18px;
    color: #666666;
}
/deep/ .ant-col {
    font-size: 14px;
    color: #999999;
    line-height: 28px;
    margin-bottom: 8px;
}
.breadcrumb{
    min-width: 960px;
    font-size: 14px;
}
</style>