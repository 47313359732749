<template>
  <div class="search-list">
    <!-- 搜索有结果 -->
    <div v-if="true">
        <!-- 导航 -->
        <a-breadcrumb separator=">" class="breadcrumb">
            <a-breadcrumb-item>
                <a @click.prevent="$router.push('/index')">首页</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                搜索结果页
            </a-breadcrumb-item>
        </a-breadcrumb>
        <!-- 搜索条件 -->
        <div class="search-condition">
            <div class="condition-content" v-for="(condition,cIndex) in searchList" :key="cIndex">
                <div class="name">{{condition.label}}</div>
                <ul>
                    <li :class="condition.switch === index && 'active'" v-for="(item,index) in condition.list" :key="index" @click="condition.switch = index;searchCondition()">{{item}}</li>
                </ul>
            </div>
        </div>

        <!-- 搜索结果 -->
        <div class="search-result">
            <div class="result-chunk" v-for="(item,index) in 32" :key="index" @click="$router.push('/shop/:id')">
                <p class="site">
                    <a-icon type="home" />
                    宣城市宣州区张三再生资源回收站
                </p>
                <p class="name">
                    <a-icon type="user-add" />
                    张三
                </p>
                <p class="product">主营：HDPE,ABS,EVA,废铜，废铝</p>
            </div>
        </div>

        <a-pagination class="pagination" :current="10" :total="50" :item-render="itemRender"/>
    </div>
    <!-- 搜索无结果 -->
    <NullResult v-else />
  </div>
</template>

<script>
import NullResult from '@/components/SearchComponent/NullResult.vue'
export default {
    components: {
        NullResult
    },
    data() {
        return {
            searchList: [
                {label: '缴税地区：', list: ['全部','青岛胶州','宣城宣州','宣城宁国'], switch: 0},
                {label: '支持税点：', list: ['全部','增值税3%','增值税1%'], switch: 0},
            ]
        }
    },
    methods: {
        attrChange(e, attr) {
            this[attr] = e
        },
        // 触发筛选条件
        searchCondition() {
            // ...
        },
        itemRender(current, type, originalElement) {
            if (type === 'prev') {
                return <a class="ant-pagination-item-link pagination-style"><a-icon type="left" />上一页</a>;
            } else if (type === 'next') {
                return <a class="ant-pagination-item-link pagination-style">下一页<a-icon type="right" /></a>;
            }
            return originalElement;
        },
    }
}
</script>

<style lang="less" scoped>
.search-list {
    margin: 0 auto;
    width: 1440px;
    .search-condition {
        padding: 10px 64px;
        background: #fff;
        border: 1px solid @border;
        margin-bottom: 10px;
    }
    .condition-content {
        line-height: 50px;
        border-bottom: 1px dashed #e9e9e9;
        &:last-child {
            border-bottom: none;
        }
        .name {
            display: inline-block;
            width: 66px;
            line-height: 28px;
            color: #999;
        }
        ul {
            display: inline-block;
        }
        li {
            display: inline-block;
            cursor: pointer;
            line-height: 28px;
            width: 65px;
            text-align: center;
            margin-right: 5px;
            &:hover {
                color: @primary;
            }
        }
        .active {
            background: @primary;
            color: #fff !important;
        }
        .select {
            width: 84px;
            font-size: 12px;
            display: inline-block;
            margin-right: 5px;
        }
    }
    .search-result {
        .result-chunk {
            cursor: pointer;
            display: inline-block;
            margin: 20px 24px 0 0;
            width: 282px;
            padding: 16px;
            border-radius: 8px;
            background: #fff;
            p {
                margin-bottom: 12px;
            }
            .site {
                color: #808000;
                font-size: 14px;
                font-weight: bold;
            }
            .name {
                color: #7F7F7F;
                font-size: 14px;
            }
            .product {
                color: #aaa;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}
.pagination {
    margin-top: 48px;
    margin-bottom: 112px;
    text-align: center;
}
.pagination-style {
    width: 80px;
}
</style>