<template>
  <div class="search-list">
    <!-- 搜索有结果 -->
    <a-spin :spinning="loading">
        <!-- 导航 -->
        <a-breadcrumb separator=">" class="breadcrumb">
            <a-breadcrumb-item>
                <!-- <a @click.prevent="$router.push('/index')">首页</a> -->
                全部结果
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <a-tag :closable="($route.query.address || $route.query.product) && true" v-if="$route.query.address || $route.query.product" @close="$router.push('/product-list?product=')">
                    "{{$route.query.address || $route.query.product}}"
                </a-tag>
                <!-- {{$route.query.address || '商品列表'}} -->
            </a-breadcrumb-item>
        </a-breadcrumb>
        <!-- 搜索条件 -->
        <div class="search-condition">
            <div class="condition-content" v-for="(condition,cIndex) in searchList" :key="cIndex">
                <div class="name">{{condition.label}}</div>
                <ul v-if="cIndex !== 4">
                    <li :class="(item.id && condition.switch === item.id || condition.switch === item.key) && 'active'" v-for="(item,index) in condition.list" :key="index" @click="searchCondition(condition,item)">{{item.name || item.label}}</li>
                </ul>
                <span v-else>
                    <a-select class="select" placeholder="来源" @change="attrChange($event, 'source')">
                        <a-select-option v-for="(list,index) in sourceList" :key="index" :value="index">
                            {{list}}
                        </a-select-option>
                    </a-select>
                    <a-select class="select" placeholder="级别" @change="attrChange($event, 'source')">
                        <a-select-option v-for="(list,index) in rankList" :key="index" :value="index">
                            {{list}}
                        </a-select-option>
                    </a-select>
                    <a-select class="select" placeholder="颜色" @change="attrChange($event, 'source')">
                        <a-select-option v-for="(list,index) in colorList" :key="index" :value="index">
                            {{list}}
                        </a-select-option>
                    </a-select>
                </span>
            </div>
        </div>

        <!-- 排序条件 -->
        <div class="sort-condition">
            <ul>
                <li :class="sortIndex === 0 &&'active'" @click="sortIndex = 0;form.orderBy = 0;form.sort = undefined;form.pageNum = 1;getList()">
                    <a-space>综合排序</a-space>
                </li>
                <li :class="sortIndex === 1 &&'active'" @click="sortIndex = 1;changePrice()">
                    <a-space>价格<a-icon :type="sortPrice ? 'caret-up' : 'caret-down'" @click.native="sortIndex = 1;sortPrice = !sortPrice;changePrice()" /></a-space>
                </li>
                <li :class="sortIndex === 2 &&'active'" @click="sortIndex = 2;changeTime()">
                    <a-space>发布时间<a-icon :type="sortTime ? 'caret-up' : 'caret-down'" @click.native="sortIndex = 2;sortTime = !sortTime;changeTime()" /></a-space>
                </li>
                <!-- <li>
                    <div class="more">
                        <a-input class="price-input" v-model="priceMin" placeholder="￥"></a-input>
                        &nbsp;-&nbsp;
                        <a-input class="price-input" v-model="priceMax" placeholder="￥"></a-input>
                        <p>
                            <span @click="priceMin='';priceMax=''">取消</span>
                            <a-button type="primary" size="small">确定</a-button>
                        </p>
                    </div>
                </li> -->
            </ul>
        </div>

        <!-- 搜索结果 -->
        <div class="search-result" v-if="total!=0">
            <router-link v-for="(item,index) in searchResult" :key="index" :to="`/product-details?id=${item.id}`">
                <div class="result-list">
                    <a-row>
                        <a-col :span="11" class="l-flex">
                            <img class="" :src="item.imgPath || noImg" alt="">

                            <div class="list-info">
                                <span class="info">{{item.mdseName}}</span>
                                
                                <div class="info-detail">
                                    <span class="beyond_eip">
                                        <a-icon type="home" />
                                        {{item.supplierName}}
                                    </span>
                                    <span>
                                        <a-icon type="user-add" />
                                        {{item.legalName}}
                                    </span>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="4">
                            <div class="price-info">
                                <p>
                                    <b>￥{{item.unitPrice}}</b>/{{item.unitsMeasure | unit}}
                                </p>
                                <p>{{item.quantity}}{{item.unitsMeasure | unit}}</p>
                            </div>
                        </a-col>
                        <a-col :span="9">
                            <div class="site-info">
                                <p>缴税地区：{{item.regionName}}</p>
                                <p>货源地：{{item.sourceAddress}}</p>
                                <p>发布日期：{{item.createdTime}}</p>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </router-link>
        </div>

        <!-- 搜索无结果 -->
        <NullResult v-else />

        <a-pagination class="pagination" v-if="total!=0" :current="form.pageNum" :total="total"  @change="form.pageNum=$event;getList()" :item-render="itemRender"/>
    </a-spin>
  </div>
</template>

<script>
import NullResult from '@/components/SearchComponent/NullResult.vue'
import {regions,list} from '@/api/public'
import { type } from "@/utils/constArr";
import area from '@/utils/ara'
import cookie from '@/utils/js-cookie.js'
export default {
    components: {
        NullResult
    },
    filters: {
        unit:(val)=>type[val]
    },
    data() {
        return {
            noImg: require('../../assets/images/noImg.png'),
            searchResult: [],
            searchList: [
                // {label: '分类：', list: ['全部','低压聚乙烯','尼龙','聚丙烯','聚氯乙烯','聚碳酸酯','聚酯','高压聚乙烯','其他'], switch: 0},
                // {label: '规格/形态：', list: ['全部','破碎','不限'], switch: 0},
                {label: '缴税地区：', list: [], switch: 0},
                {label: '货源地：', list: [{key: undefined, label: '全部'},...area], switch: undefined},
                // {label: '属性：', source: null, rank: null, color: null,},
            ],
            sourceList: ['工厂废料', '下角料', '外壳', '瓶子', '薄膜', '袋子', '包装'],
            rankList: ['A级', 'B级', 'C级', '1级', '2级', '3级'],
            colorList: ['黑色', '灰色', '彩色', '浅色'],
            sortIndex: 0, // 排序
            priceMove: false, // 经过价格区间的开关
            priceMin: '', // 价格区间最小值
            priceMax: '', // 价格区间最大值
            total: 0,
            form: {
                searchText: this.$route.query.product || this.$route.query.address || undefined,
                taxRegionId: undefined,
                provinceCode: undefined,
                beginTime: undefined,
                endTime: undefined,
                pageNum: 1,
                pageSize: 10
            },
            sortTime: false,
            sortPrice: false,
            loading: false,
        }
    },
    mounted() {
        regions().then(res => {
            console.log(res)
            this.searchList[0].list = [{id: 0 , name: '全部', key: 0} ,...res.data]
        })
        this.getList()
    },
    methods: {
        attrChange(e, attr) {
            this[attr] = e
        },
        getList() {
            this.loading = true
            list(this.form).then(res => {
                this.loading = false
                this.searchResult = res.data.list
                this.total = res.data.total
            })
        },
        // 触发筛选条件
        searchCondition(condition, item) {
            if (condition.label === '缴税地区：') {
                condition.switch = item.id
                this.form.taxRegionId = condition.switch || undefined
                this.form.pageNum = 1
                this.getList()
            } else {
                console.log(item);
                condition.switch = item.key
                this.form.provinceCode = condition.switch
                this.form.pageNum = 1
                this.getList()
            }
        },
        itemRender(current, type, originalElement) {
            if (type === 'prev') {
                return <a class="ant-pagination-item-link pagination-style"><a-icon type="left" />上一页</a>;
            } else if (type === 'next') {
                return <a class="ant-pagination-item-link pagination-style">下一页<a-icon type="right" /></a>;
            }
            return originalElement;
        },
        changePrice() {
            this.form.pageNum = 1
            this.form.sort = this.sortPrice ? 1 : 0
            this.form.orderBy = 1
            this.getList()
        },
        changeTime() {
            this.form.pageNum = 1
            this.form.sort = this.sortTime ? 1 : 0
            this.form.orderBy = 2
            this.getList()
        },
        to(id) {
            if (cookie.get('hasLogin')) {
                window.open(window.location.origin +`/product-details?id=${id}`)
            } else {
                this.$message.error('请先登录')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.search-list {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
        padding: 0 15px;
    }
    .sort-condition {
        font-size: 14px;
        background: #fff;
        border: 1px solid @border;
        line-height: 40px;
        ul {
            display: inline-block;
        }
        li {
            width: 100px;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            &:nth-child(4) {
                width: auto;
                cursor: initial;
                position: relative;
                width: 200px;
                p {
                    display: none;
                    padding: 0 10px;
                    span {
                        cursor: pointer;
                    }
                }
            }
            i {
                color: #d7d7d7;
            }
            &:hover {
                color: @primary;
            }
            .more {
                &:hover {
                    display: block;
                    position: absolute;
                    top: -25px;;
                    width: 100%;
                    background: #fff;
                    z-index: 1;
                    p {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            .price-input {
                width: 80px;
                display: inline-block;
            }
        }
        .active {
            color: @subColor !important;
            background: #fff;
            i {
                color: @subColor !important;
            }
        }
    }
    .search-condition {
        padding: 10px 64px;
        background: #fff;
        border: 1px solid @border;
        margin-bottom: 10px;
    }
    .condition-content {
        line-height: 50px;
        border-bottom: 1px dashed #e9e9e9;
        &:nth-child(5) {
            border-bottom: none;
        }
        .name {
            display: inline-block;
            vertical-align: top;
            margin-top: 11px;
            width: 70px;
            line-height: 28px;
            color: #999;
            float: left;
        }
        ul {
            margin-left: 70px;
        }
        li {
            display: inline-block;
            cursor: pointer;
            line-height: 28px;
            padding: 0 12px;
            text-align: center;
            margin-right: 5px;
            &:hover {
                color: @primary;
            }
        }
        .active {
            background: @primary;
            color: #fff !important;
        }
        .select {
            width: 84px;
            font-size: 12px;
            display: inline-block;
            margin-right: 5px;
        }
    }
    // .search-result {
    //     // padding: 0 14px;
    // }
    .result-list {
        background: #fff;
        margin: 12px 0;
        padding: 20px 14px;
        border: 1px solid @border;
        transition: all .3s;
        &:hover {
            border: 1px solid @subColor;
        }
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
        .list-info {
            width: calc(100% - 80px);
            padding-left: 24px;
            height: 80px;
            position: relative;
            .info-detail {
                position: absolute;
                bottom: 0;
                color: #999;
                display: flex;
                width: 100%;
                justify-content: space-between;
                font-size: 14px;
                i {
                    margin-right: 5px;
                }
                span {
                    &:nth-child(1) {
                        max-width: 300px;
                    }
                }
            }
            .info {
                cursor: pointer;
                font-weight: 650;
                font-size: 16px;
                line-height: 20px;
                text-overflow: ellipsis;
                // display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        .price-info {
            p {
                font-size: 14px;
                color: #666666;
                // width: 180px;
                text-align: right;
                &:nth-child(1) {
                    margin-bottom: 8px;
                }
            }
            b {
                color: @error;
                font-size: 20px;
            }
        }
        .site-info {
            p {
                font-size: 14px;
                color: #AAAAAA;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}
.pagination {
    margin-top: 48px;
    margin-bottom: 112px;
    text-align: center;
}
.pagination-style {
    width: 80px;
}
.breadcrumb{
    font-size: 14px;
    width: 100%;
}
/deep/ .ant-tag {
    padding: 4px 4px 4px 10px;
    font-size: 14px;
    background-color: rgba(215, 215, 215, 1);
}
</style>