<template>
  <div>
    <NoLoginNav />

    <div class="container">
      <!-- 左侧 -->
      <div class="register-content">
        <p class="register-title">找回密码</p>
        <div class="lay">
          <uInput
          ref="phone"
          :sign="true"
          :max="11"
          v-model="form.phone"
          placeholder="输入手机号码"
          @blur="verify"
          :rule="rule[0]"
          @clear="form.phone = $event">
              <template #icon>
                  <a-icon type="mobile" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          :sign="true"
          v-model="form.smsCode"
          :hasClear="false"
          max="6"
          placeholder="短信验证码"
          :rule="rule[1]">
              <span :class="switchCode && 'verify-code'" @click="sendMsg">{{count === 60 ? '发送验证码' : `${count}秒后重发`}}</span>
              <template #icon>
                  <a-icon type="message" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          type="password"
          max="20"
          :sign="true"
          v-model="form.password"
          placeholder="设置6至20位登录密码"
          :rule="rule[2]"
          :isPwd='false'
          @clear="form.password = $event">
              <template #icon>
                  <a-icon type="lock" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          type="password"
          max="20"
          :sign="true"
          v-model="form.newPwd"
          placeholder="请再次输入登录密码"
          :isPwd='false'
          :rule="rule[3]"
          @clear="form.newPwd = $event">
              <template #icon>
                  <a-icon type="lock" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uButton @click.native="register">确认</uButton>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="register-info">
        <p class="info-login">没有中再物贸云网账号，<router-link to="/register">立即注册</router-link></p>
      </div>
    </div>
    <FootMain />
  </div>
</template>

<script>
import NoLoginNav from '@/layout/NoLoginNav'
import uInput from '@/components/uInput'
import uButton from '@/components/uButton'
import FootMain from '@/layout/FootMain'
import { upPwd,sendSms,effectPhone } from '@/api/user.js'
import md5 from 'js-md5'
export default {
    components: {
        NoLoginNav,
        uInput,
        uButton,
        FootMain
    },
    beforeRouteEnter(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#fff')
        document.querySelector('html').setAttribute('style', 'background-color:#fff')
        next()
    },
    beforeRouteLeave(to, from, next) {
        document.querySelector('body').setAttribute('style', '')
        document.querySelector('html').setAttribute('style', '')
        next()
    },
    data() {
      return {
        form: {
          phone: '',
          smsCode: '',
          newPwd: '',
          password: '',
        },
        rule: [
          {rule: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, errorMsg: '输入的手机号码不正确'},
          {rule: /^\d{6}$/, errorMsg: '输入的验证码不正确'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的密码不满6位'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的密码不满6位'},
        ],
        // 协议勾选
        agreementCheck: true,
        // 发送验证码
        count: 60,
        switchCode: false
      }
    },
    methods: {
      // 获取验证码
      sendMsg() {
        if (this.$refs.phone.verifyRule() && !this.switchCode) {
          sendSms(this.form.phone).then(res => {
            if (res.code === 200) {
              this.switchCode = true
              this.countDown()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      },
      countDown() {
        if (this.count > 0) {
          this.count--
          setTimeout(this.countDown, 1000)
        } else {
          this.switchCode = false
          this.count = 60
        }
      },
      verify() {
        if (this.form.phone.length === 11) {
          effectPhone({phone: this.form.phone}).then(res => {
            if (res.code !== 200) {
              console.log(res);
              this.$refs.phone.ruleResult = true
              this.rule[0].errorMsg = res.message
            }
          })
        }
        
      },
      // 注册
      register() {
        if (!this.agreementCheck) {
          // 未勾选判断 ...
          return
        }
        this.rule = [
          {rule: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, errorMsg: '输入的手机号码不正确'},
          {rule: /^\d{6}$/, errorMsg: '输入的验证码不正确'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的密码不满6位'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的密码不满6位'},
        ]
        let verify = true
        this.$children.forEach((item) => {
          let test
          item.$attrs.sign && !( test = item.verifyRule()) && (verify = verify && test)
        })
        if (this.form.newPwd !== this.form.password) {
          this.$message.error('请输入相同的密码')
          return
        }
        if (!verify) return
        const config = {...this.form, password: undefined}
        config.newPwd = md5(config.newPwd)
        console.log(config);
        upPwd(config).then(res => {
          if (res.code === 200) {  
            this.$message.success(res.message)
            this.$router.replace('/login')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
}
</script>

<style lang="less" scoped> 
.container {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
  margin: 0 auto;
  margin-top: 22px;
  display: flex;
  // justify-content: space-between;
  .register-content {
    margin-top: 30px;
    width: 900px;
    .register-title {
      color: #000;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #999999;
      margin-left: 24px;
      margin-bottom: 96px;
    }
    .lay {
      margin-left: 162px;
      margin-bottom: 20px;
      span {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: @primary;
      }
      .verify-code {
        color: #cccccc;
      }
    }
    .agreement {
      font-size: 14px;
      i {
        margin: 0 2px 0 12px;
        color: #ccc;
        border-radius: 50%;
        border: 2px solid #ccc;
      }
      .check {
        color: #fff;
        background: @primary;
        border: 2px solid @primary;
      }
    }
  }
  .register-info {
    width: 300px;
    height: 666px;
    padding-left: 52px;
    border-left: 1px solid @border;
    font-size: 14px;
    .info-login {
      margin: 100px 0;
      color: #666666;
    }
    .info-title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 32px;
    }
  }
}
</style>