<template>
  <div class="order-details">
      <div class="order-header">
        <div class="l-flex-between">
          <p class="header">订单：{{$route.query.id}}</p>
          <!-- <a-rate :value="3" /> -->
        </div>
        <a-tabs>
          <a-tab-pane key="1" tab="信息流">
            <Details />
          </a-tab-pane>
          <a-tab-pane key="5" tab="合同流">
            <Contract />
          </a-tab-pane>
          <a-tab-pane key="2" tab="资金流">
            <Pay />
          </a-tab-pane>
          <a-tab-pane key="3" tab="货流">
            <Logistics />
          </a-tab-pane>
          <a-tab-pane key="4" tab="票流">
            <Invoice />
          </a-tab-pane>
        </a-tabs>
      </div>
  </div>
</template>

<script>
import Details from './components/Details.vue'
import Pay from './components/Pay.vue'
import Logistics from './components/Logistics.vue'
import Invoice from './components/Invoice.vue'
import Contract from './components/Contract.vue'

export default {
  components: {
    Details,
    Pay,
    Logistics,
    Invoice,
    Contract
  }
}
</script>

<style lang="less" scoped>
.order-header {
  // padding-top: 18px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
      padding: 0 15px;
  }
  margin: 0 auto;
  // background: #fff;
  .l-flex-between {
    padding: 20px 20px;
    background: #fff;
  }
  .header {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    font-weight: 700;
  }
}
/deep/ .ant-tabs-nav-scroll {
    padding: 0 20px;
    background: #fff;
}
</style>