<template>
  <div class="payment">
    <div class="paybody">
      <PayMoney :bord="true" title="订单总金额" :total="total" />
      <div class="arrows">
        <img src="../../../assets/images/u203.svg" alt="" />
      </div>
      <div class="pay-count">
        <PayMoney title="贷款金额" :total="net" />
        <PayMoney title="税款金额" :total="tax" />
      </div>
    </div>
    <div class="order-count">
      <!-- <a-row type="flex" justify="end" style="padding:0 0 20px 0 ">
        <a-col :span="3"><a-button type="primary">新增支付</a-button></a-col>
        <a-col :span="2"><a-button type="primary">下载回单</a-button></a-col>
      </a-row> -->
      <a-table rowKey='id' :columns="columns" :dataSource="list" 
      :pagination="{size:'middle',current: listInfo.pageNum,pageSize: listInfo.pageSize,showSizeChanger: true,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                listInfo.pageSize = pageSize
                listInfo.pageNum = 1
                this.getlist()
              },
              onChange: (pageNum) =>  {
              listInfo.pageNum = pageNum 
              this.getlist()}
            }" size="middle">
        <template slot="id" slot-scope="text">
          <a class="id-primary">{{ text }}</a>
        </template>
        <template slot="state" slot-scope="text,record">
          <span v-if="record.state=='success'"><a-badge status="success" /></span>
          <span v-else-if="record.state==''"><a-badge status="error" /></span>
          <span v-else-if="record.state=='1'"> <a-badge status="default" /></span>
          <span v-else-if="record.state=='2'">  <a-badge status="processing" /></span>
          <span>{{text}}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import PayMoney from "../../buyercenter/Order/components/pay.vue";
import {list,progress}  from '@/api/pay'
const columns = [
  {
    title: "支付ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "创建时间",
    align:'left',
    dataIndex: "createdTime",
  },
  {
    title: "支付编号",
    align:'left',
    dataIndex: "paymentNo",
  },
  {
    title: "收款方账号",
    align:'left',
    dataIndex: "receiptAccountNo",
  },
  {
    title: "订单关联贷款",
    align:'right',
    dataIndex: "netAmount",
  },
  {
    title: "关联税费",
    align:'right',
    dataIndex: "taxAmount",
  },
  {
    title: "状态",
    dataIndex: "payStatus",
    align:'left',
    scopedSlots: { customRender: "payStatus" },
  },
  {
    title: "支付渠道",
    dataIndex: "channelType",
  },
  {
    title: "到账时间",
    dataIndex: "settleTime",
  },
  // {
  //   title: "操作",
  //   dataIndex: "action",
  //   scopedSlots: { customRender: "action" },
  // },
];
export default {
  components: {
    PayMoney,
  },
  data() {
    return {
      columns,
      list:[],
      listInfo:{
        orderId:this.$route.query.id,
        pageNum:1,
        pageSize:10
      },
      total:{},
      net:{},
      tax:{}
    };
  },
  created(){
    this.getlist()
  },
  methods:{
    getlist(){
      list({...this.listInfo}).then(res=>{
        this.list=res.data.list
      })
      progress({orderId:this.$route.query.id}).then(res=>{
        this.total=res.data.total
        this.net=res.data.net
        this.tax=res.data.tax
      })
    },
  }
};
</script>

<style lang="less" scoped>
.payment {
  padding: 20px;
  background-color: #FFFFFF;
  .paybody {
    @media screen and (max-width: 1440px){
        transform: scale(.85);
    }
    display: flex;
    justify-content: center;
    .arrows {
      padding: 60px 0 0 10px;
    }
    .pay-count {
      display: flex;
      justify-content: space-evenly;
      width: 747px;
      height: 196px;
      background-color: rgba(242, 242, 242, 1);
      border-radius: 5px;
      padding: 20px 20px;
    }
  }
  .order-count {
    padding: 50px 0 0 0;
  }
}
</style>