import { render, staticRenderFns } from "./StoreDetails.vue?vue&type=template&id=c67d19d0&scoped=true&"
import script from "./StoreDetails.vue?vue&type=script&lang=js&"
export * from "./StoreDetails.vue?vue&type=script&lang=js&"
import style0 from "./StoreDetails.vue?vue&type=style&index=0&id=c67d19d0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c67d19d0",
  null
  
)

export default component.exports